import React from 'react';

import {
    Stack,
    Typography
} from '@mui/material';

import {
    Text
} from 'components';

const styles = {

    root: {
        background: 'linear-gradient(to bottom, #1c1c1c, #111)',
        padding: '16px'
    },
};

const Information = ({team}) => {
    return (
        <Stack sx={styles.root}>
            <Stack mb="24px">
                <Stack>
                    <Typography color="grey.mainlight"><Text>global.name</Text></Typography>
                </Stack>
                <Stack>
                    {team.name ?
                        <Typography color="grey.mainlight2">{team.name}</Typography> :
                        <Typography color="grey.mainlight2"><Text>ui.placeholder.textfield</Text></Typography>
                    }
                </Stack>
            </Stack>
        </Stack>
    );
};

export default Information;