import {create} from 'zustand';
import {persist} from 'zustand/middleware';

import {middleware} from 'store/zustand';

import {produce} from 'immer';

const defaultLang = {
    code: 'de',
    name: 'board.menu.language.german'
};

export const languages = [
    defaultLang,
    {
        code: 'en',
        name: 'board.menu.language.english'
    },
    {
        code: 'nl',
        name: 'board.menu.language.dutch'
    },
    {
        code: 'es',
        name: 'board.menu.language.spanish'
    },
    {
        code: 'fr',
        name: 'board.menu.language.french'
    }
];

export const useSettingsStore = create(persist(middleware((set, _get) => ({
    selectedTeam: null,
    // availableLanguages: languages,
    selectedLanguage: defaultLang.code,
    setSelectedLanguage: language => set( produce({}, draft => {
        draft.selectedLanguage = language;
    })),
    setSelectedTeam: team => set(produce(draft => {
        draft.selectedTeam = team;
    })),
}), {name: 'SettingsStore', debug: true}),
{
    name: 'settings', // unique name
    version: 2,
    partialize: (state) => Object.fromEntries(
        Object.entries(state).filter(([key]) => !['selectedTeam'].includes(key))
    ),
    migrate: (persistedState, previousVersion) => {

        const migrations = [
            (state) => state, // version 0
            (state) => state, // version 1
            // version 2
            (state) => {
                for (const key of [
                    'quicklink', 'settingsQuicklink', 'players', 'teams', 'clients',
                    'active', 'selectedClient']) {
                    delete state[key];
                }

                return state;
            },
        ];

        let state = persistedState;

        for (let i = previousVersion + 1; i < migrations.length; i++) {
            state = migrations[i](state);
        }

        return state;
    }
}));
