import React from 'react';

import {
    Drawer as MuiDrawer
} from '@mui/material';

const styles = {
    drawer: {
        width: '100%',
        backgroundColor: 'black.light'
    },
};

const Drawer = ({children, sx = {}, ...rest}) => {
    return <MuiDrawer transitionDuration={400} SlideProps={{appear: true}} anchor="left" PaperProps={{sx: [styles.drawer, sx]}} {...rest}>{children}</MuiDrawer>;
};

export default Drawer;