import React, {useState} from 'react';

import {
    Avatar,
    Stack, Tab, Tabs,
    Typography,
} from '@mui/material';

import {
    Drawer,
    MenuHeader,
    Text} from 'components';
import {imageUrl} from 'lib/media';
import {useLayoutStore} from 'store';

import Equipment from './Equipment';
import Information from './Information';
import {useInfo} from './useInfo';

const styles = {
    avatar: {
        width: 300,
        height: 300
    },
    clientHeadline: {
        fontSize: '31px',
        color: 'grey.mainlight2'
    },
};

const Member = ({member}) => {
    const [tab, setTab] = useState('information');

    const closeMenu = useLayoutStore(state => state.closeMenu);
    const setShowMember = useLayoutStore(state => state.setShowMember);

    const memberInfo = useInfo();
    const {name, initials} = memberInfo(member);

    const goBack = () => {
        setShowMember(false);
    };

    console.log(member);

    return (
        <Drawer open={true} onClose={goBack}>
            <MenuHeader text={member.name} handleBackButton={goBack} handleClose={closeMenu}/>

            <Stack direction="column" pt={2} alignItems="center">
                <Stack justifyContent="center" alignItems="center" mb={2}>
                    <Avatar sx={styles.avatar} src={imageUrl(member.user?.image, {w: 300})}>
                        {initials}
                    </Avatar>
                </Stack>
                <Stack direction="row" alignItems="center" mb={1}>
                    <Typography mr={3} sx={styles.clientHeadline}>{name}</Typography>
                </Stack>
                <Tabs value={tab} onChange={(_e, tab) => setTab(tab)}>
                    <Tab value="information" label={<Text>team.player.data</Text>}/>
                    <Tab value="equipment" label={<Text>user.equipment</Text>}/>
                </Tabs>
            </Stack>

            {tab === 'information' && <Information member={member}/>}
            {tab === 'equipment' && <Equipment member={member}/>}

        </Drawer>
    );
};

export default Member;