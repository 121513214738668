import React from 'react';

import {
    Stack,
    Typography
} from '@mui/material';

import {
    Text
} from 'components';
import {useInfo} from 'module/client/team/member/useInfo';

const styles = {
    root: {
        background: 'linear-gradient(to bottom, #1c1c1c, #111)',
        padding: '40px 20px'
    },
};

const Information = ({member}) => {
    const memberInfo = useInfo();
    const {firstname, lastname, initials, roles} = memberInfo(member);

    return (
        <Stack sx={styles.root} direction="column" justifyContent="space-between">
            <Stack direction="row" mb={1}>
                <Typography color="grey.mainlight" maxWidth="200px" minWidth="200px" mr={2}><Text>global.person.firstname</Text></Typography>
                <Typography color="grey.mainlight">{firstname}</Typography>
            </Stack>
            <Stack direction="row" mb={1}>
                <Typography color="grey.mainlight" maxWidth="200px" minWidth="200px" mr={2}><Text>global.person.lastname</Text></Typography>
                <Typography color="grey.mainlight">{lastname}</Typography>
            </Stack>
            <Stack direction="row" mb={1}>
                <Typography color="grey.mainlight" maxWidth="200px" minWidth="200px" mr={2}><Text>user.birthday</Text></Typography>
                <Typography color="grey.mainlight">{member.user?.birthday}</Typography>
            </Stack>
            <Stack direction="row" mb={1}>
                <Typography color="grey.mainlight" maxWidth="200px" minWidth="200px" mr={2}><Text>user.initials</Text></Typography>
                <Typography color="grey.mainlight">{initials}</Typography>
            </Stack>
            <Stack direction="row" mb={1}>
                <Typography color="grey.mainlight" maxWidth="200px" minWidth="200px" mr={2}><Text>client.role.roles</Text></Typography>
                <Typography color="grey.mainlight">{roles}</Typography>
            </Stack>
            <Stack direction="row" mb={1}>
                <Typography color="grey.mainlight" maxWidth="200px" minWidth="200px" mr={2}><Text>user.body.height</Text></Typography>
                <Typography color="grey.mainlight">{member.user?.body?.height} cm</Typography>
            </Stack>
            <Stack direction="row" mb={1}>
                <Typography color="grey.mainlight" maxWidth="200px" minWidth="200px" mr={2}><Text>user.body.weight</Text></Typography>
                <Typography color="grey.mainlight">{member.user?.body?.weight} kg</Typography>
            </Stack>
        </Stack>
    );
};

export default Information;