import React, {useEffect, useState} from 'react';

import {Add as AddIcon} from '@mui/icons-material';
import {
    Fab,
    List,
    Stack,
    Typography
} from '@mui/material';

import {
    ConfirmDialog,
    Drawer,
    MenuHeader,
    Text, useConfirmDialog
} from 'components';
import {useTranslation} from 'hooks';
import {MenuItem} from 'module/client/index';
import {useUserUtils} from 'module/user/useUser';
import {useLayoutStore, useSnackbarStore} from 'store';

import Create from './Create';
import Edit from './Edit';
import {IconButton} from './index';
import {useApiDelete, useApiQuery} from './useClientApi';

const styles = {
    actionButtonContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        padding: '16px',
        borderBottom: '1px solid',
        borderBottomColor: 'grey.darkest2',
        cursor: 'pointer',
        background: 'linear-gradient(to bottom, #1c1c1c, #111)',
    },
    actionButton: {
        flexShrink: 0,
        backgroundColor: 'grey.darker',
        fontSize: '30px',
        marginRight: '16px',
        ':hover': {
            backgroundColor: 'grey.darker',
        },
    },
    listHolder: {
        padding: 0,
    },
};

const Client = ({client, onClick, onToggleMenu, showMenu, onDelete, onEdit}) => {
    const translate = useTranslation();

    const {isOwner} = useUserUtils();

    const collapseButtons = [];

    if (isOwner(client.user_id)) {
        collapseButtons.push(<IconButton
            key="pencil"
            icon="pencil"
            onClick={() => onEdit(client)}
        />);
        collapseButtons.push(<IconButton
            key="delete"
            icon="delete"
            color="red.dark"
            onClick={() => onDelete(client)}
        />);
    }

    return <MenuItem
        onClick={() => onClick(client)}
        avatarText={client.name}
        image={client.image}
        listItemTextPrimary={client.name}
        listItemTextSecondary={`${client.teams.length} ${translate('team.teams')}, ${client.members.length} ${translate('client.members')}`}
        pullerFunction={(e) => onToggleMenu(e, client)}
        collapseButtons={collapseButtons}
        showMenu={showMenu}
    />;
};

const Menu = () => {
    const translate = useTranslation();

    const [selection, setSelection] = useState(null);
    const [showCreate, setShowCreate] = useState(false);
    const [showEdit, setShowEdit] = useState(false);

    const setShowClient = useLayoutStore(state => state.setShowClient);
    const showClientMenu = useLayoutStore(state => state.showClientMenu);
    const setShowClientMenu = useLayoutStore(state => state.setShowClientMenu);
    const closeAll = useLayoutStore(state => state.closeMenu);

    const setShowLoading = useLayoutStore((state) => state.setShowLoading);

    const showInfo = useSnackbarStore((state) => state.show);

    const {del, loading} = useApiDelete();

    const query = useApiQuery();

    const onSave = async (newClient) => {
        await query.refetch();
        setShowCreate(false);
        setSelection(newClient);
    };

    const onClose = () => {
        setShowCreate(false);
    };

    const handleDelete = (client) => {
        deleteConfirm.show(client);
    };

    const deleteConfirm = useConfirmDialog(
        async (client) => {
            try {
                await del(client.id);
                query.refetch();
                showInfo(translate('client.action.delete_success'));
            } catch (e) {
                showInfo(e.message, {severity: 'warning'});
            }
        },
        undefined,
        translate('client.action.confirm_delete')
    );

    const handleEdit = (_id) => {
        setShowEdit(true);
    };

    const handleSelect = (client) => {
        setShowClient(true, client);
    };

    const closeMenu = () => {
        setShowClientMenu(false);
    };

    const toggleMenu = (e, client) => {
        if (e.dir === 'Left') {
            setSelection(client);
        } else if (e.dir === 'Right') {
            setSelection(null);
        } else {
            setSelection(selection === client ? null : client);
        }
    };

    const createHandler = () => {
        setShowCreate(true);
    };

    useEffect(() => {
        setShowLoading(loading || query.isFetching);
        return () => {
            setShowLoading(false);
        };
    }, [loading, query.isFetching, setShowLoading]);

    return (
        <Drawer open={showClientMenu} onClose={closeMenu}>
            <ConfirmDialog {...deleteConfirm} />
            <Create onSave={onSave} onClose={onClose} open={showCreate} />
            {showEdit && <Edit client={selection} onClose={() => setShowEdit(false)} onSave={onSave}/>}
            <MenuHeader
                text={<Text>Teammanagment</Text>}
                handleBackButton={closeMenu}
                handleClose={closeAll}
            />

            <Stack
                sx={styles.actionButtonContainer} onClick={createHandler} direction="row"
                alignItems="center">
                <Fab disableRipple sx={styles.actionButton}><AddIcon color="white"/></Fab>
                <Typography><Text>client.action.create2</Text></Typography>
            </Stack>

            <List sx={styles.listHolder}>
                {query.data?.map(client => <Client
                    key={client.id}
                    client={client}
                    onClick={handleSelect}
                    onToggleMenu={toggleMenu}
                    onEdit={handleEdit}
                    onDelete={handleDelete}
                    showMenu={selection?.id === client.id}/>)}
            </List>
        </Drawer>
    );
};

export default Menu;