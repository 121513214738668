import React, {useState} from 'react';

import {
    Avatar,
    Stack, Tab, Tabs,
    Typography
} from '@mui/material';

import {
    Drawer,
    MenuHeader,
    Text} from 'components';
import {imageUrl} from 'lib/media';
import Information from 'module/client/Information';
import Teams from 'module/client/team/Teams';
import {useLayoutStore} from 'store';

const styles = {
    clientLogoHolder: {
        width: 160,
        height: 160,
        borderRadius: '50%',
        backgroundColor: 'white.main',
        padding: 2,
        position: 'relative'
    },
    clientHeadlineContainer: {
        overflow: 'hidden',
        width: '80%',
        marginBottom: 1,
    },
    clientHeadline: {
        fontSize: '18px',
        color: 'grey.mainlight2',
        whiteSpace: 'nowrap',
    },
    avatar: {
        width: 160,
        height: 160
    },
    tabTitleText: {
        fontSize: '16px',
        fontWeight: '300',
        color: 'grey.mainlight',
    }
};

const Client = ({client}) => {
    const [tab, setTab] = useState('teams');

    const closeMenu = useLayoutStore(state => state.closeMenu);
    const setShowClient = useLayoutStore(state => state.setShowClient);

    const goBack = () => {
        setShowClient(false);
    };

    return (
        <Drawer open={true} onClose={goBack}>
            <MenuHeader text={client.name} handleBackButton={goBack} handleClose={closeMenu}/>

            <Stack direction="column" alignItems="center">
                <Stack sx={styles.clientLogoHolder} justifyContent="center" alignItems="center" my="20px">
                    <Avatar sx={styles.avatar} alt={client.name} src={imageUrl(client.image, {w: 100, h: 100})}/>
                </Stack>
                <Stack sx={styles.clientHeadlineContainer}>
                    <Typography sx={styles.clientHeadline} textAlign="center">{client.name}</Typography>
                </Stack>
                <Tabs value={tab} onChange={(_e, tab) => setTab(tab)}>
                    <Tab value="teams" label={<Typography sx={styles.tabTitleText}><Text>team.teams</Text></Typography>}/>
                    <Tab value="information" label={<Typography sx={styles.tabTitleText}><Text>global.information</Text></Typography>}/>
                </Tabs>
            </Stack>

            {tab === 'teams' && <Teams client={client}/>}
            {tab === 'information' && <Information client={client}/>}
        </Drawer>
    );
};

export default Client;