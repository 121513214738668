import React, {useState} from 'react';

import {
    Stack, Tab, Tabs,
    Typography, useTheme
} from '@mui/material';
import {useQueryClient} from '@tanstack/react-query';

import Icons from 'assets/icons';
import {
    Drawer,
    MenuHeader,
    Text
} from 'components';
import {imageUrl} from 'lib/media';
import Information from 'module/client/team/Information';
import Members from 'module/client/team/member/Members';
import {useSettingsStore} from 'module/settings/zustand';
import {useLayoutStore} from 'store';

const styles = {
    teamLogoHolder: {
        width: '100%',
        position: 'relative',
        '& img': {
            display: 'block',
            maxWidth: '100%',
            height: 'auto',
            maxHeight: '750px'
        },
    },
    clientHeadline: {
        fontSize: '18px',
        color: 'grey.mainlight2'
    },
    clientHeadlineContainer: {
        overflow: 'hidden',
        width: '100%',
        paddingX: '20px',
        marginBottom: 1,
    },
    clientHeadlineContainerInline: {
        overflow: 'hidden',
        width: '100%',
        whiteSpace: 'nowrap',
        marginRight: 3,
    },
    clientHeadlineIcon: {
        flexShrink: 0,
        cursor: 'pointer',
    },
    tabTitleText: {
        fontSize: '16px',
        fontWeight: '300',
        color: 'grey.mainlight',
    }
};

const Team = ({team}) => {
    const theme = useTheme();

    const [tab, setTab] = useState('members');

    const closeMenu = useLayoutStore(state => state.closeMenu);
    const setShowTeam = useLayoutStore(state => state.setShowTeam);

    const setSelectedTeam = useSettingsStore(state => state.setSelectedTeam);

    const queryClient = useQueryClient();

    const goBack = () => {
        setShowTeam(false);
    };

    const selectTeam = () => {
        queryClient.invalidateQueries({queryKey: ['user-team', team.id]});
        setSelectedTeam(team.id);
        closeMenu();
    };

    const iconStyle = {...styles.clientHeadlineIcon, fill: theme.palette.white.main};

    return (
        <Drawer open={true} onClose={goBack}>
            <MenuHeader text={team.name} handleBackButton={goBack} handleClose={closeMenu}/>

            <Stack direction="column" pt={2} alignItems="center">
                {team.image &&
                    <Stack sx={styles.teamLogoHolder} justifyContent="center" alignItems="center" mb={2}>
                        <img src={imageUrl(team.image, {w: 600})} alt={team.name}/>
                    </Stack>
                }
                <Stack direction="row" alignItems="center" sx={styles.clientHeadlineContainer}>
                    <Stack sx={styles.clientHeadlineContainerInline}>
                        <Typography sx={styles.clientHeadline}>{team.name}</Typography>
                    </Stack>
                    <Icons.ArrowSwitch
                        sx={styles.clientHeadlineIcon} width={32} height={32} style={iconStyle}
                        onClick={selectTeam}/>
                </Stack>
                <Tabs value={tab} onChange={(_e, tab) => setTab(tab)}>
                    <Tab
                        value="members"
                        label={<Typography sx={styles.tabTitleText}><Text>client.members</Text></Typography>}/>
                    <Tab
                        value="information"
                        label={<Typography sx={styles.tabTitleText}><Text>global.information</Text></Typography>}/>
                </Tabs>
            </Stack>

            {tab === 'members' && <Members team={team}/>}
            {tab === 'information' && <Information team={team}/>}

        </Drawer>
    );
};

export default Team;