import React from 'react';

import PersonIcon from '@mui/icons-material/Person';
import {
    Avatar,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    useTheme
} from '@mui/material';

import Icons from 'assets/icons';
import {Drawer, MenuHeader} from 'components';
import {useTranslation} from 'hooks';
import {isDevelopment} from 'lib/env';
import {imageUrl} from 'lib/media';
import {hasPairingApi} from 'module/pairing/Api';
import {useUserStore} from 'module/user';
import {useUserUtils} from 'module/user/useUser';
import {useLayoutStore} from 'store';

const styles = {
    overlay: {
        padding: 0,
        width: '100%',
        zIndex: '990',
    },
    menuButton: {
        padding: '0',
        backgroundColor: 'black.light',
    },
    listIconAvatar: {
        marginRight: '20px',
        width: 40,
        height: 40,
        flexShrink: 0,
        backgroundColor: 'transparent',
    },
    listIcon: {
        marginRight: '20px',
        width: 40,
        height: 40,
        flexShrink: 0,
    },
    listItemTextStyle: {
        whiteSpace: 'wrap !important',
    },
};

const MainMenu = () => {
    const translate = useTranslation();
    const theme = useTheme();

    const showDrawer = useLayoutStore((state) => state.showDrawer);
    const setShowDrawer = useLayoutStore((state) => state.setShowDrawer);
    //const setShowSettings = useLayoutStore((state) => state.setShowSettings);
    const setShowClientMenu = useLayoutStore((state) => state.setShowClientMenu);
    const setShowProfile = useLayoutStore((state) => state.setShowProfile);
    const setShowLanguage = useLayoutStore((state) => state.setShowLanguage);
    const setShowInformationDialog = useLayoutStore((state) => state.setShowInformationDialog);
    const setShowGroupMenu = useLayoutStore((state) => state.setShowGroupMenu);
    const setShowPairing = useLayoutStore((state) => state.setShowPairing);

    const user = useUserStore(state => state.user);
    const {isAuthenticated} = useUserUtils();

    const userInfo = user ? `${user?.firstname} ${user?.lastname}` : '';

    function handleClose() {
        setShowDrawer(false);
    }

    const iconStyle = {
        ...styles.listIcon,
        fill: theme.palette.grey.mainlight
    };

    return <Drawer open={showDrawer} onClose={() => setShowDrawer(false)}>
        <List sx={styles.overlay}>
            <MenuHeader text={translate('board.menu.main')} handleBackButton={handleClose} handleClose={handleClose} />

            {/* Profil und Einstellungen */}
            <ListItem sx={styles.menuButton}>
                <ListItemButton onClick={() => setShowProfile(true)}>
                    <Avatar style={styles.listIconAvatar} src={imageUrl(user?.image, {w: 40, h: 40})} alt={`${user?.firstname} ${user?.lastname}`}>
                        {user?.image?.url ?
                            typeof user?.firstname === 'string' ? user?.firstname[0] : '' + typeof user?.firstname === 'string' ? user?.lastname[0] : '' :
                            <PersonIcon sx={{color: 'grey.mainlight', fontSize: 40}} />
                        }
                    </Avatar>
                    <ListItemText primary={translate('board.menu.profile')} secondary={userInfo} secondaryTypographyProps={{sx: styles.listItemTextStyle}} />
                </ListItemButton>
            </ListItem>

            {/* Themen & Folien */}
            <ListItem sx={styles.menuButton}>
                <ListItemButton onClick={() => setShowGroupMenu(true, 'drawer')}>
                    <Icons.CopySlide style={iconStyle} />
                    <ListItemText primary={translate('board.menu.my_groups_sheets')} secondary={translate('board.menu.subtitle.groups_sheets')} secondaryTypographyProps={{sx: styles.listItemTextStyle}} />
                </ListItemButton>
            </ListItem>

            {/* Teammanagment */}

            {isAuthenticated && <ListItem sx={styles.menuButton}>
                <ListItemButton onClick={() => setShowClientMenu(true)}>
                    <Icons.Managment style={iconStyle} />
                    <ListItemText primary={translate('board.menu.my_clubs')} secondary={translate('board.menu.subtitle.my_clubs')} secondaryTypographyProps={{sx: styles.listItemTextStyle}} />
                </ListItemButton>
            </ListItem>}

            {/* Kalender */}
            {/*<ListItem sx={styles.menuButton}>*/}
            {/*    <ListItemButton>*/}
            {/*        <Icons.Calendar style={iconStyle} />*/}
            {/*        <ListItemText primary={'Kalender'} secondary={'Besprechungen & Trainings'}/>*/}
            {/*    </ListItemButton>*/}
            {/*</ListItem>*/}

            {/* Digital Locker Room */}

            {/* <ListItem sx={styles.menuButton}>
                <ListItemButton>
                    <Icons.Lockerroom style={iconStyle} />
                    <ListItemText primary={'Digital Locker Room'} secondary={'Live Taktikbesprechungen'}/>
                </ListItemButton>
            </ListItem> */}

            {/* QR Code Scanner */}
            {/*<ListItem sx={styles.menuButton}>*/}
            {/*    <ListItemButton>*/}
            {/*        <Icons.QrCode style={iconStyle} />*/}
            {/*        <ListItemText primary={'QR Code Scanner'}/>*/}
            {/*    </ListItemButton>*/}
            {/*</ListItem>*/}

            {/* Tutorials Videos */}
            {/*<ListItem sx={styles.menuButton}>*/}
            {/*    <ListItemButton>*/}
            {/*        <Icons.Videos style={iconStyle} />*/}
            {/*        <ListItemText primary={translate('board.menu.tutorials')}/>*/}
            {/*    </ListItemButton>*/}
            {/*</ListItem>*/}

            {/* FAQ */}
            {/*<ListItem sx={styles.menuButton}>*/}
            {/*    <ListItemButton>*/}
            {/*        <Icons.Faq style={iconStyle} />*/}
            {/*        <ListItemText primary={'FAQ'}/>*/}
            {/*    </ListItemButton>*/}
            {/*</ListItem>*/}

            {/* Follow us */}
            {/*<ListItem sx={styles.menuButton}>*/}
            {/*    <ListItemButton>*/}
            {/*        <Icons.Follow style={iconStyle} />*/}
            {/*        <ListItemText primary={'Follow us'}/>*/}
            {/*    </ListItemButton>*/}
            {/*</ListItem>*/}

            {/* Kontakt */}
            <ListItem sx={styles.menuButton}>
                <ListItemButton onClick={() => setShowInformationDialog(true)}>
                    <Icons.Contact style={iconStyle} />
                    <ListItemText primary={translate('global.contact')} secondary={translate('board.menu.subtitle.contact')} secondaryTypographyProps={{sx: styles.listItemTextStyle}} />
                </ListItemButton>
            </ListItem>

            <ListItem sx={styles.menuButton}>
                <ListItemButton onClick={() => setShowLanguage(true)}>
                    <Icons.Language style={iconStyle} />
                    <ListItemText primary={translate('board.menu.language.language')} secondary={translate('board.menu.subtitle.language')} secondaryTypographyProps={{sx: styles.listItemTextStyle}} />
                </ListItemButton>
            </ListItem>

            {/* Pairing */}
            {(hasPairingApi() || isDevelopment()) && <ListItem sx={styles.menuButton} key={'Pairing'}>
                <ListItemButton onClick={() => setShowPairing(true)}>
                    <Icons.Connect style={iconStyle} />
                    <ListItemText primary={translate('board.menu.pairing')} secondary={translate('board.menu.subtitle.synchronise')} secondaryTypographyProps={{sx: styles.listItemTextStyle}} />
                </ListItemButton>
            </ListItem>}
        </List>
    </Drawer>;
};

export default MainMenu;