import React from 'react';

import {
    Stack,
    Typography, useTheme
} from '@mui/material';

import Icons from 'assets/icons';
import {
    Text
} from 'components';

const styles = {
    root: {
        background: 'linear-gradient(to bottom, #1c1c1c, #111)',
        padding: '40px 20px'
    },
};

const Item = ({icon, label, value, unit}) => {
    const theme = useTheme();

    const Icon = Icons[icon];

    return <Stack direction="row" mb={1}>
        <Icon width={32} height={32} style={{fill: theme.palette.white.main, marginRight: '10px'}}/>
        <Stack direction="row">
            <Typography
                color="grey.mainlight" maxWidth="200px" minWidth="200px"
                mr={2}><Text>{label}</Text></Typography>
            <Typography color="grey.mainlight">{value} {unit}</Typography>
        </Stack>
    </Stack>;
};

const Equipment = ({member}) => {

    return (
        <Stack sx={styles.root} direction="column" justifyContent="space-between">
            <Item icon="Shoe" label="user.size.shoe" value={member.user?.size.shoe}/>
            <Item icon="Shirt" label="user.size.shirt" value={member.user?.size.shirt}/>
            <Item icon="Pants" label="user.size.pants" value={member.user?.size.pants}/>
            <Item icon="Head" label="user.size.head" value={member.user?.size.head}/>
            <Item icon="Hoodie" label="user.size.jacket" value={member.user?.size.jacket}/>
        </Stack>
    );
};

export default Equipment;