import React, {useEffect, useMemo, useState} from 'react';

import {Add as AddIcon, PersonAdd as PersonAddIcon} from '@mui/icons-material';
import {
    Fab,
    List,
    ListItem,
    ListItemText,
    Stack,
    Typography
} from '@mui/material';
import {Box} from '@mui/system';

import {
    ConfirmDialog,
    Text, useConfirmDialog
} from 'components';
import {useTranslation} from 'hooks';
import {IconButton} from 'module/client/index';
import {MenuItem} from 'module/client/index';
import {positions} from 'module/client/team/useTeamApi';
import {useUserUtils} from 'module/user/useUser';
import {useLayoutStore, useSnackbarStore} from 'store';

import Create from './Create';
import Edit from './Edit';
import Invite from './Invite';
import {useInfo} from './useInfo';
import {groupByPosition, useApiDelete, useApiQuery} from './useMemberApi';

const styles = {
    actionButtonContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        padding: '16px',
        borderBottom: '1px solid',
        borderBottomColor: 'grey.darkest2',
        cursor: 'pointer',
        background: 'linear-gradient(to bottom, #1c1c1c, #111)',

        '&:last-child': {
            borderBottom: 0,
        }
    },
    actionButton: {
        flexShrink: 0,
        backgroundColor: 'grey.darker',
        fontSize: '30px',
        marginRight: '16px',
        ':hover': {
            backgroundColor: 'grey.darker',
        },
    },
    listHolder: {
        padding: 0,
        marginBottom: '16px',
    },
    listHolderLast: {
        padding: '0 0 40px 0',
    },
    listItem: {
        width: '100%',
    },
    listItemTitle: {
        backgroundColor: 'grey.darkest2',
        padding: '3px 16px',
        margin: 0,
    },
    listItemTitleText: {
        fontWeight: '300',
        color: 'grey.mainlight'
    },
    boldText: {
        fontWeight: '600',
        marginRight: '5px',
        marginLeft: '5px',
    },
};

const Member = ({member, onClick, onToggleMenu, showMenu, onDelete, onEdit, isManager}) => {
    const memberInfo = useInfo();
    const {firstname, lastname, initials, trainer} = memberInfo(member);

    const doClick = () => {
        if (!member.user) {
            return;
        }

        onClick(member);
    };

    const collapseButtons = [];

    if (isManager) {
        collapseButtons.push(<IconButton
            key="pencil"
            icon="pencil"
            onClick={() => onEdit(member)}
        />);
        collapseButtons.push(<IconButton
            key="delete"
            icon="delete"
            color="red.dark"
            onClick={() => onDelete(member)}
        />);
    }

    return <MenuItem
        onClick={doClick}
        avatarText={initials}
        image={member.user?.image}
        listItemTextPrimary={<Stack direction="row" alignItems="center">{firstname}<Box
            sx={styles.boldText}>{lastname}</Box>{trainer}</Stack>}
        showBadge={member?.user && true}
        moreText={member?.number}
        pullerFunction={(e) => onToggleMenu(e, member)}
        collapseButtons={collapseButtons}
        showMenu={showMenu}
    />;
};

const Members = ({team}) => {
    const translate = useTranslation();

    const [selection, setSelection] = useState(null);
    const [showEdit, setShowEdit] = useState(false);
    const [showCreate, setShowCreate] = useState(false);
    const [showInvite, setShowInvite] = useState(false);

    const {isOwner} = useUserUtils();
    const isManager = isOwner(team.managers);

    const setShowLoading = useLayoutStore((state) => state.setShowLoading);
    const setShowMember = useLayoutStore(state => state.setShowMember);

    const showInfo = useSnackbarStore((state) => state.show);

    const {del, loading} = useApiDelete();

    const query = useApiQuery({teamId: team.id});

    const members = useMemo(() => {
        return groupByPosition(query.data, true);
    }, [query.data]);

    const handleEdit = (_id) => {
        setShowEdit(true);
    };

    const handleDelete = (member) => {
        deleteConfirm.show(member);
    };

    const deleteConfirm = useConfirmDialog(
        async (member) => {
            try {
                del(team.id, member.id);
                query.refetch();
                showInfo(translate('client.action.member.delete_success'));
            } catch (e) {
                showInfo(e.message, {severity: 'warning'});
            }
        },
        undefined,
        translate('client.action.member.confirm_delete')
    );

    const handleSelect = (member) => {
        setShowMember(true, member);
    };

    const onSave = async (newTeam) => {
        setShowCreate(false);
        await query.refetch();
        setSelection(newTeam);
    };

    const toggleMenu = (e, member) => {
        if (e.dir === 'Left') {
            setSelection(member);
        } else if (e.dir === 'Right') {
            setSelection(null);
        } else {
            setSelection(selection === member ? null : member);
        }
    };

    useEffect(() => {
        setShowLoading(query.isFetching || loading);
        return () => {
            setShowLoading(false);
        };
    }, [query.isFetching, setShowLoading, loading]);

    // const iconStyle = {
    //     ...styles.absolutIcon,
    //     fill: theme.palette.white.main
    // };

    return (
        <>
            <ConfirmDialog {...deleteConfirm} />
            {showEdit && <Edit team={team} member={selection} onClose={() => setShowEdit(false)} onSave={onSave}/>}
            {showCreate && <Create team={team} onClose={() => setShowCreate(false)} onSave={onSave}/>}
            {showInvite && <Invite team={team} onClose={() => setShowInvite(false)} onSave={onSave}/>}

            {isManager && <Stack mb="16px" sx={styles.listItem}>
                <Stack
                    sx={styles.actionButtonContainer} direction="row" alignItems="center"
                    onClick={() => setShowCreate(true)}>
                    <Fab disableRipple sx={styles.actionButton}><AddIcon color="white"/></Fab>
                    <Typography><Text>client.action.member.add</Text></Typography>
                </Stack>
                <Stack
                    sx={styles.actionButtonContainer} direction="row" alignItems="center"
                    onClick={() => setShowInvite(true)}>
                    <Fab disableRipple sx={styles.actionButton}><PersonAddIcon color="white"/></Fab>
                    <Typography><Text>client.action.member.invite</Text></Typography>
                </Stack>
            </Stack>}

            {/*<Stack sx={[styles.actionButtonContainer, styles.actionButtonNormalBg]} direction="row" alignItems="center">*/}
            {/*    <Stack width={56} alignItems="center" mr={2}>*/}
            {/*        <Icons.Startelf width={30} height={30} ml={2} style={iconStyle}/>*/}
            {/*    </Stack>*/}
            {/*    <Stack flexGrow="1" color="white.main"><Text>Startelf festlegen</Text></Stack>*/}
            {/*    <Icons.Pencil width={25} height={25} style={iconStyle}/>*/}
            {/*</Stack>*/}

            {positions.map(position => <List key={position} sx={styles.listHolder}>
                <ListItem>
                    <ListItemText sx={styles.listItemTitle}><Typography
                        sx={styles.listItemTitleText}>{translate(`team.position.${position}`)}</Typography></ListItemText>
                </ListItem>
                {members[position].map(member => <Member
                    key={member.id}
                    member={member}
                    isManager={isManager}
                    onToggleMenu={toggleMenu}
                    onDelete={handleDelete}
                    onEdit={handleEdit}
                    onClick={handleSelect}
                    showMenu={selection?.id === member.id}/>)}

                {members[position].length < 1 && <Typography color="grey.lightest" px="15px" py="10px" fontWeight="300">Keine
                    Mitglieder</Typography>}

            </List>)}

            <List sx={[styles.listHolder, styles.listHolderLast]}>
                <ListItem>
                    <ListItemText sx={styles.listItemTitle}><Typography
                        sx={styles.listItemTitleText}><Text>client.officials</Text></Typography></ListItemText>
                </ListItem>
                {members.officials.map(member => <Member
                    key={member.id}
                    member={member}
                    isManager={isManager}
                    onToggleMenu={toggleMenu}
                    onEdit={handleEdit}
                    onDelete={handleDelete}
                    onClick={handleSelect}
                    showMenu={selection?.id === member.id}/>)}

                {members.officials.length < 1 && <Typography color="grey.lightest" px="15px" py="10px" fontWeight="300">Keine
                    Mitglieder</Typography>}
            </List>
        </>
    );
};

export default Members;