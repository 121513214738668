import {useCallback} from 'react';

import {isOk} from 'fetcher/useFetcher';
import {isDevelopment,isOfflineDevelopment} from 'lib/env';
import {Modes} from 'lib/tactix';
import {useLockerRoomStore} from 'module/lockerRoom/zustand';
import {useGetActiveLockerrooms} from 'module/user/api';
import {useUserStore} from 'module/user/zustand';
import {useInfoStore} from 'store';

export const useLoadActiveLockerrooms = () => {
    const jwt = useUserStore(state => state.user?.jwt);
    const setLockerrooms = useUserStore(state => state.setLockerrooms);
    const roomId = useLockerRoomStore(state => state.roomId);
    const setIsLoadingLockerrooms = useUserStore(state => state.setIsLoadingLockerrooms);
    const mode = useInfoStore(state => state.mode);

    const {load} = useGetActiveLockerrooms();

    return useCallback(async () => {
        if (!isDevelopment() && mode !== Modes.APP) {
            return;
        }

        // local
        if (roomId && isOfflineDevelopment()) {
            console.log('LOCKERROOM: local server mode rooms',[{id: '1', room_id: roomId}]);
            setLockerrooms([{id: '1', room_id: roomId}]);
            setIsLoadingLockerrooms(false);
            return;
        }

        if (jwt) {
            let rooms = [];
            const res = await load();

            if (isOk(res)) {
                rooms = Array.isArray(res.data) ? res.data : [];
            }

            console.debug('rooms', rooms);

            setLockerrooms(rooms);
            setIsLoadingLockerrooms(false);

        } else {
            // reduce logging noise
            setLockerrooms([]);
            setIsLoadingLockerrooms(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [jwt, roomId, mode]);
};
