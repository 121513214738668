import React from 'react';

import {
    Clear as CloseIcon,
} from '@mui/icons-material';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import {Button, ButtonBase, Stack, Typography} from '@mui/material';

const styles = {
    menuHeader: {
        color: 'white.main',
        height: {
            mobile: '50px',
            desktop: '60px',
        },
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        backgroundColor: 'black.dark',
        paddingX: '16px',
    },
    menuHeaderButton: {
        color: 'grey.mainlight',
        fontSize: '16px',
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'flex-end',
        padding: 0,
        margin: 0,
        minWidth: 'auto',
        width: 'auto',
        backgroundColor: 'transparent',
        '&:hover': {
            backgroundColor: 'transparent',
        },
        flexShrink: 0,
    },
    menuHeaderButtonLast: {
        paddingLeft: '16px',
    },
    menuHeaderText: {
        fontSize: '18px',
        display: 'flex',
        alignItems: 'center',
        flexGrow: 1,
        height: {
            mobile: '50px',
            desktop: '60px',
        },
        color: 'grey.mainlight',
        whiteSpace: 'nowrap',
        overflow: 'hidden',

        fontWeight: '300',
        paddingLeft: '16px',
    },
};

const MenuHeader = ({text, handleBackButton, handleClose}) => {
    return (
        <Stack sx={styles.menuHeader} direction="row" alignItems="center">

            {handleBackButton &&
                <ButtonBase sx={styles.menuHeaderButton} onClick={handleBackButton} disableRipple>
                    <KeyboardBackspaceIcon/>
                </ButtonBase>
            }

            <Typography sx={styles.menuHeaderText}>{text}</Typography>

            {handleClose && <Button sx={[styles.menuHeaderButton, styles.menuHeaderButtonLast]} disableRipple onClick={handleClose}>
                <CloseIcon/>
            </Button>}

        </Stack>
    );
};

export default MenuHeader;