import lockerRoom from 'module/lockerRoom/socket';

// import {useInfoStore} from 'store';
import {useLockerRoomStore} from './zustand';

import * as mediasoupClient from 'mediasoup-client';

const mediaType = {
    audio: 'audioType',
    video: 'videoType',
    screen: 'screenType'
};

window.mediasoupClient = mediasoupClient;

let producer = null;
let startProduce = false;
/**
 * map that contains a mediatype as key and producerId as value
*/

const consumers = new Map();
const producers = new Map();
const producerLabel = new Map();

const loadDevice = async () => {
    const setMediaClientDevice = useLockerRoomStore.getState().setMediaClientDevice;
    const mediaClientDevice = useLockerRoomStore.getState().mediaClientDevice;

    if (!mediaClientDevice) {
        console.log('mediasoupClient device1', mediaClientDevice);
        let device;
        // loads the media device
        try {
            device = await new mediasoupClient.Device();
            // console.log(device);
            // console.log(this.mediasoupClient.Device());
            if (device) {
                const routerRtpCapabilities = await lockerRoom.request('getRouterRtpCapabilities');
                if (routerRtpCapabilities) {
                    await device.load({routerRtpCapabilities});
                    setMediaClientDevice(device);
                    console.log('mediasoupClient device finish', device);
                }
            }
        } catch (error) {
            if (error.name === 'UnsupportedError') {
                console.error('User agent unknown');
                //alert('User agent unknown');
            }
            
            console.error('MEDIASOUP: LOAD DEVICE ERROR', error);
        }
    } 

};

const getConsumeStream = async (producerId) => {
    const consumerTransport = useLockerRoomStore.getState().consumerTransport;

    if (!consumerTransport || !producerId || consumerTransport?._closed === true) {

        console.error('MEDIASOUP: GET CONSUME STREAM ERROR');
        return null;
    }
    
    const mediaClientDevice = useLockerRoomStore.getState().mediaClientDevice;
    const {rtpCapabilities} = mediaClientDevice;
    // TODO move lockerroom request  to transports medialib

    const data = await lockerRoom.request('consume', {
        rtpCapabilities,
        consumerTransportId: consumerTransport?.id, // might be
        producerId
    });
    console.log('getConsumeStream', data);
    if (data) {
        
        const {id, kind, rtpParameters} = data;
        if (id && kind && rtpParameters) {
            
            let codecOptions = {};
            const consumer = await consumerTransport.consume({
                id,
                producerId,
                kind,
                rtpParameters,
                codecOptions
            });
            
            const stream = new MediaStream();
            stream.addTrack(consumer.track);
            
            return {
                consumer,
                stream,
                kind
            };
        } else {return null;}
    } else {
        return null;
    }
};

//MAIN EXPORTS

const produce = async (type, deviceId = null, role, paused) => {
    const mediaClientDevice = useLockerRoomStore.getState().mediaClientDevice;
    const producerTransport = useLockerRoomStore.getState().producerTransport;
    const setProducerTransportConnected = useLockerRoomStore.getState().setProducerTransportConnected;
    const setProducerTransport = useLockerRoomStore.getState().setProducerTransport;
    if (startProduce || !mediaClientDevice?._loaded || !producerTransport || producerTransport?._closed === true) {
        console.debug('MEDIASOUP PRODUCE ERROR started:', startProduce,' Device ready:', mediaClientDevice?._loaded,' Transport:', producerTransport);
        return false;
    }
    
    startProduce = true;
    console.log('MEDIASOUP PRODUCE', type, deviceId, role, paused);
    let mediaConstraints = {};
    let audio = false;
    let screen = false;

    switch (type) {
    case mediaType.audio:
        mediaConstraints = {
            audio: {
                deviceId: deviceId,
                autoGainControl: true,
                noiseSuppression: true,
                echoCancellation: true,
            },
            video: false
        };
        audio = true;
        break;
    case mediaType.video:
        mediaConstraints = {
            audio: false,
            video: {
                width: {
                    min: 640,
                    ideal: 1920
                },
                height: {
                    min: 400,
                    ideal: 1080
                },
                deviceId: deviceId
                /*aspectRatio: {
                        ideal: 1.7777777778
                    }*/
            }
        };
        break;
    case mediaType.screen:
        mediaConstraints = false;
        screen = true;
        break;
    default:
        return;
    }

    if (!mediaClientDevice?.canProduce('video') && !audio) {
        console.error('Cannot produce video');
        startProduce = false;
        return;
    }

    if (producerLabel.has(type)) {
        console.log('Producer already exists for this type ' + type);
        startProduce = false;
        return;
    }

    console.log('Mediacontraints:', mediaConstraints);
    let stream;
    
    try {
        stream = screen
            ? await navigator.mediaDevices.getDisplayMedia()
            : await navigator.mediaDevices.getUserMedia(mediaConstraints);
        
        const track = audio ? stream.getAudioTracks()[0] : stream.getVideoTracks()[0];
        const params = {
            track
        };

        if (!audio && !screen) {
            params.encodings = [
                {
                    rid: 'r0',
                    maxBitrate: 100000,
                    //scaleResolutionDownBy: 10.0,
                    scalabilityMode: 'S1T3'
                },
                {
                    rid: 'r1',
                    maxBitrate: 300000,
                    scalabilityMode: 'S1T3'
                },
                {
                    rid: 'r2',
                    maxBitrate: 900000,
                    scalabilityMode: 'S1T3'
                }
            ];
            params.codecOptions = {
                videoGoogleStartBitrate: 1000
            };
        }

        try {
            producer = await producerTransport.produce(params);

        } catch (error) {
            console.log('producerTransport produce error', error);
            startProduce = false;

            return false;
        }

        console.log('Producer starting PAUSED', paused );
        // TODO maybe remove role to use server state
        if (role !== 'admin' && (paused === undefined || paused)){
            producer.pause();
            
            // pause - server
            lockerRoom.emit('producerPaused', {producerId: producer?.id});
        }

        producers.set(producer?.id, producer);
        
        let elem;

        // if (!audio) {
        //     elem = document.createElement('video');
        //     elem.srcObject = stream;
        //     elem?.id = producer?.id;
        //     elem.playsinline = false;
        //     elem.autoplay = true;
        //     elem.className = 'vid';
        //     // videoEl.appendChild(elem);
        //     handleFS(elem?.id);
        // }

        producer.on('trackended', () => {
            closeProducer(type);
        });

        producer.on('transportclose', () => {
            console.log('Producer transport close');
            setProducerTransport(null);
            setProducerTransportConnected(false);
            closeProducer(type);
        });

        producer.on('close', () => {
            console.log('Closing producer');
            if (!audio) {
                elem?.srcObject.getTracks().forEach((track) => {
                    track.stop();
                });
                elem?.parentNode.removeChild(elem);
            }

            producers.delete(producer?.id);
            startProduce = false;

        });

        producerLabel.set(type, producer?.id);

        return true;
    } catch (err) {
        console.log('Produce error:', err);
        startProduce = false;
        return false;
    }
    finally {
        startProduce = false;
    }
};

const consume = async (producerId) => {
    const mediaClientDevice = useLockerRoomStore.getState().mediaClientDevice;
    const consumerTransport = useLockerRoomStore.getState().consumerTransport;

    if (!producerId || !mediaClientDevice?._loaded || !consumerTransport) {
        console.log('MEDIASOUP CONSUME no producerid', producerId, consumerTransport, mediaClientDevice);
        return;
    }

    let returnValue = false;
    // check if already a consumer for producerId to prevent double audio
    consumers.forEach((key) => {
        if (key.producerId === producerId) {
            console.log('MEDIASOUP CONSUME producerid already connected', producerId);
            returnValue = true;
        }
    });
      
    if (returnValue) {
        return;
    }

    const consumeStream = await getConsumeStream(producerId);
    if (consumeStream) {
        const {consumer, stream, kind} = consumeStream;

        consumers.set(consumer?.id, consumer);
    
        let elem;
        console.log('CONSUMER', kind, consumer?.id, consumers);
        if (kind === 'video') {
            // elem = document.createElement('video');
            // elem.srcObject = stream;
            // elem?.id = consumer?.id;
            // elem.playsinline = false;
            // elem.autoplay = true;
            // elem.className = 'vid';
            // // videoEl?.appendChild(elem);
            // handleFS(elem?.id);
        } else {
            elem = document.createElement('audio');
            elem.srcObject = stream;
            elem.id = consumer?.id;
            elem.playsinline = false;
            elem.autoplay = true;
            const root = document.getElementById('root');
            document.body.insertBefore(elem, root);

            // audioEl?.appendChild(elem);
            // consumerTransport.handler.getReceiverStats(consumer?.id).then((result) => {
            //     let report = result.lastWhere((report) => report.type == 'ssrc');
            //     console.log(report);
            // }).catch ((err) => { console.log(err);});
        }
    
        consumer.on(
            'trackended', () => removeConsumer(consumer?.id)
        );
    
        consumer.on(
            'transportclose', () => removeConsumer(consumer?.id)
        );
    } else {
        console.log('MEDIASOUP CONSUME no consumestream, producerid:', producerId);

    }

};

export const closeProducer = (type) => {
    if (!producerLabel.has(type)) {
        return;
    }

    // todo fix loop where new producertransport gets removed
    let producerId = producerLabel.get(type);
    console.log('Close producer', producerId);

    lockerRoom.emit('producerClosed', {
        producerId
    });

    producers.get(producerId)?.close();
    producers.delete(producerId);
    producerLabel.delete(type);

    startProduce = false;

    if (type !== mediaType.audio) {
        let elem = document.getElementById(producerId);
        elem?.srcObject.getTracks().forEach((track) => {
            track.stop();
        });
        elem?.parentNode.removeChild(elem);
    }

};

const pauseProducer = (type) => {
    if (!producerLabel.has(type)) {
        console.log('pauseProducer There is no producer for this type ' + type);
        return;
    }

    let producerId = producerLabel.get(type);
    // pause - client
    producers.get(producerId).pause();
    // pause - server
    lockerRoom.emit('producerPaused', {producerId});
};

const resumeProducer = (type) => {
    if (!producerLabel.has(type)) {
        console.log('resumeProducer There is no producer for this type ' + type);
        // why no producer? reconnect the producer
        return;
    }

    let producerId = producerLabel.get(type);
    // pause - client
    producers.get(producerId).resume();
    // pause - server
    lockerRoom.emit('producerResumed', {producerId});
};

export const removeConsumer = (consumerId) => {
    console.log('Remove consumer', consumerId);
    let elem = document.getElementById(consumerId);
    // TODO not working 
    console.log('consumers', elem);
    elem?.srcObject.getTracks().forEach((track) => {
        track.stop();
    });
    elem?.parentNode.removeChild(elem);

    consumers.delete(consumerId);
};

const exitRoom = (offline = false, comeBack, role, roomId, appId, selectedMediaDevices, producing) => {
    console.log('EXIT ROOM', offline, comeBack, role, roomId, appId, selectedMediaDevices, producing);
    // const producerTransport = useLockerRoomStore.getState().producerTransport;
    // const consumerTransport = useLockerRoomStore.getState().consumerTransport;
    // const setProducerTransport = useLockerRoomStore.getState().setProducerTransport;
    // const setConsumerTransport = useLockerRoomStore.getState().setConsumerTransport;
    // const setProducerTransportConnected = useLockerRoomStore.getState().setProducerTransportConnected;
    // const setConsumerTransportConnected = useLockerRoomStore.getState().setConsumerTransportConnected;
    // const setMediaRoomConnected = useLockerRoomStore.getState().setMediaRoomConnected;
    // const setMediaRoomCreated = useLockerRoomStore.getState().setMediaRoomCreated;
    // const setMediaRoomForceReconnect = useLockerRoomStore.getState().setMediaRoomForceReconnect;
    // const setMediaClientDevice = useLockerRoomStore.getState().setMediaClientDevice;
    const reset = useLockerRoomStore.getState().reset;

    let clean = () => {
        console.log('EXIT ROOM CLEAN');
        // setProducerTransport(null);
        // setProducerTransportConnected(false);
        // setConsumerTransport(null);
        // setConsumerTransportConnected(false);
        // setMediaRoomConnected(false);
        // setMediaRoomCreated(false);
        // setMediaRoomForceReconnect(false); // reset reconnects
        // setMediaClientDevice(null); // clear local device so new transports get created
        reset(); // reset all states

        producer = null;
        /**
         * map that contains a mediatype as key and producerId as value
        */
        producerLabel.clear();
        consumers.forEach((key, value) => {
            removeConsumer(value);
        }
        );
        consumers.clear();
        producers.forEach((key, value) => {
            closeProducer(value);
        }
        );
        producers.clear();

    };

    let comeBackMethod = async (_startPaused) => {
        console.log('COMEBACK METHOD useless?');
        // await prepareRoom(roomId, appId);
        // await joinRoom(role, roomId, appId, selectedMediaDevices, startPaused );
        // setMediaRoomConnected(true);
    };

    // TODO why clean after comeback? 
    // TODO refactor to try catch
    if (!offline) {
        lockerRoom
            .request('exitRoom')
            .then((e) => { 
                if (comeBack) comeBackMethod(!producing); 
                console.log('COMEBACKMETHOD',comeBack, e);
            })
            .catch((e) => console.warn('exitroom error', e))
            .finally(
                clean()
            );
    } else {
        clean();
    }

};

const prepareMediaRoom = async (roomId, appId) => {
    // join room has to be the first request so server has the correct appid
    try {
        let response = await lockerRoom.request('joinRoom', {appId, roomId});
        console.log('prepared mediasoup room: ', response,'roomid:', roomId);
        return true;
    } catch (error) {
        console.log('prepare room error', error, appId, roomId);
        return false;
    }
};

const joinMediaRoom = async (role, roomId, appId, selectedMediaDevices, startPaused) => {
    // needs consumer and producer transports ready
    try {
        await produce(mediaType.audio, selectedMediaDevices.audioDevice, role, startPaused);
        return true;
    } catch (error) {
        console.log('join room error', error, appId, roomId);
        return false;
    }
};

const createMediaRoom = async (roomId) => {
    try {
        await lockerRoom.request('createRoom', {roomId});
        console.log('created Media Room: ', roomId);
        return true;
    } catch (error) {
        console.log('createRoom error');
        return false;
    }
};

export {mediaType, producerLabel, consumers, producers, loadDevice, getConsumeStream, produce, consume, pauseProducer, resumeProducer, exitRoom, createMediaRoom, prepareMediaRoom, joinMediaRoom};
